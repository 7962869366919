import ClearHeightCostMultiplier from './ClearHeightCostMultiplier';
import CoverageMultiplier from './CoverageMultiplier';
import Duration from './Duration';
import AsyncStorage from '@react-native-async-storage/async-storage';

class Calculator {
  // Required outputs
  public costPerSqFt;
  public totalProjectCost;
  public duration;
  public coverage;

  private inputs = {
    buildingSf: null,
    siteSf: null,
    clearHeight: null
  };

  private calculators: CalculatorInputs = {};

  private baselineCostData;

  constructor() {
    AsyncStorage.getItem('CALC_DATA').then(data => {
      const json = JSON.parse(data);
      const { newConstructionInland } = json;

      this.baselineCostData =
        newConstructionInland.dimensions.baselineCostPerSqFt;

      this.calculators.clearHeightCostMultiplier = new ClearHeightCostMultiplier(
        newConstructionInland.dimensions.baselineClearHeight,
        newConstructionInland.dimensions.clearHeightCostAdjustment
      );

      this.calculators.coverageMultiplier = new CoverageMultiplier(
        newConstructionInland.dimensions.baselineCoverage,
        newConstructionInland.dimensions.coverageCostAdjustment
      );

      this.calculators.duration = new Duration(
        newConstructionInland.dimensions.duration
      );
    });
  }

  isValidInput() {
    return (
      this.inputs.buildingSf &&
      this.inputs.buildingSf.isBetween(25001, 1250000) &&
      this.inputs.siteSf &&
      this.inputs.siteSf >= 30000 &&
      this.inputs.clearHeight &&
      this.calculators.clearHeightCostMultiplier.isValidInput() &&
      this.calculators.coverageMultiplier.isValidInput() &&
      this.calculators.duration.isValidInput()
    );
  }

  get baselineCostPerSqFt() {
    for (const value of this.baselineCostData.values) {
      if (this.inputs.buildingSf.isBetween(value.minimum, value.maximum)) {
        return parseFloat(value.output);
      }
    }
  }

  setBuildingSf(value: number) {
    this.inputs.buildingSf = value;
    this.calculators.clearHeightCostMultiplier.inputs.buildingSf = value;
    this.calculators.coverageMultiplier.inputs.buildingSf = value;
    this.calculators.duration.inputs.buildingSf = value;
    return this;
  }

  setSiteSf(value) {
    this.inputs.siteSf = value;
    this.calculators.coverageMultiplier.inputs.siteSf = value;
    return this;
  }

  setClearHeight(value: number) {
    this.inputs.clearHeight = value;
    this.calculators.clearHeightCostMultiplier.inputs.clearHeight = value;
    return this;
  }

  calculate() {
    if (!this.isValidInput()) {
      throw new Error('Invalid input');
    }
    const coverageMultiplier = this.calculators.coverageMultiplier.execute();
    const clearHeightCostAdjustment = this.calculators.clearHeightCostMultiplier.execute();
    const duration = this.calculators.duration.execute();

    this.costPerSqFt =
      this.baselineCostPerSqFt * (1 + coverageMultiplier) +
      clearHeightCostAdjustment;

    this.duration = duration;

    this.coverage = this.calculators.coverageMultiplier.coverage;

    this.totalProjectCost = Math.round(
      this.costPerSqFt * this.inputs.buildingSf
    );

    return {
      costPerSqFt: this.costPerSqFt,
      duration: this.duration,
      coverage: this.coverage,
      totalProjectCost: this.totalProjectCost
    };
  }
}

export default Calculator;

interface CalculatorInputs {
  clearHeightCostMultiplier?: ClearHeightCostMultiplier;
  coverageMultiplier?: CoverageMultiplier;
  duration?: Duration;
}
