import React, { Component } from 'react';
import {
  NavigationParams,
  NavigationScreenProp,
  NavigationState
} from 'react-navigation';
import { formatCurrency } from '../../helpers';
import SubResultContainer from '../../components/SubResultContainer';
import { NumberInput } from '../../components/inputs';
import SingleCalculatorScreen from './SingleCalculatorScreen';
import MainResult from '../../components/MainResult';
import Result from '../../components/Result';

import NewConstructionCalculator from '../../calculators/NewConstruction';
import AcreageSqFtToggle from '../../components/AcreageSqFtToggle';

class NewConstruction extends Component<Props, State> {
  calcWrapper;
  calculator: NewConstructionCalculator;

  constructor(props) {
    super(props);
    this.calcWrapper = React.createRef();
    try {
      this.calculator = new NewConstructionCalculator();
    } catch (e) {
      this.calcWrapper.showDataFetchErrorModal();
    }

    this.state = {
      buildingSf: 150000,
      siteSf: 300000,
      clearHeight: 32,
      showModal: false,
      showResults: false,
      unitType: 'sqft',
      results: {
        totalProjectCost: 0,
        costPerSqFt: 0,
        duration: 0,
        coverage: 0
      }
    };
  }

  calculateResults() {
    const { buildingSf, siteSf, clearHeight } = this.state;
    try {
      this.calculator
        .setBuildingSf(buildingSf)
        .setSiteSf(siteSf)
        .setClearHeight(clearHeight);
      const results = this.calculator.calculate();
      this.setState({ results: { ...results }, showResults: true });
    } catch (e) {
      console.log(e);
      this.setState({ showModal: true, showResults: false });
    }
  }

  getInputsArray() {
    return [
      {
        label: 'Building SqFt',
        value: Number(this.state.buildingSf).toLocaleString()
      },
      {
        label: 'Site SqFt',
        value: Number(this.state.siteSf).toLocaleString()
      },
      {
        label: 'Clear Height',
        value: this.state.clearHeight
      }
    ];
  }

  getResultsArray() {
    return [
      {
        label: 'Total Project Cost',
        value: formatCurrency(this.state.results.totalProjectCost)
      },
      {
        label: 'Cost per SqFt',
        value: formatCurrency(this.state.results.costPerSqFt)
      },
      {
        label: 'Coverage',
        value: `${(this.state.results.coverage * 100).toFixed(2)} percent`
      },
      {
        label: 'Duration',
        value: `${this.state.results.duration} months`
      }
    ];
  }

  toggleUnitTypes() {
    const prevType = this.state.unitType;
    const newType = prevType === 'sqft' ? 'acreage' : 'sqft';
    this.setState({
      unitType: newType,
      siteSf:
        prevType === 'acreage'
          ? this.state.siteSf * 43560
          : this.state.siteSf / 43560,
      showResults: false
    });
  }

  render() {
    const { showModal, showResults } = this.state;
    return (
      <SingleCalculatorScreen
        ref={this.calcWrapper}
        title="New Construction"
        subTitle="Inland Empire"
        navigation={this.props.navigation}
        inputsArray={this.getInputsArray()}
        resultsArray={this.getResultsArray()}
        showModal={showModal}
        onModalClosed={() => this.setState({ showModal: false })}
        onCalculate={() => this.calculateResults()}
        showResults={showResults}
        results={
          this.state.showResults && (
            <>
              <MainResult
                value={this.state.results.totalProjectCost}
                description="Total Project Cost"
              />
              <SubResultContainer>
                <Result
                  value={`$${this.state.results.costPerSqFt.toFixed(2)}`}
                  description="Cost per sqft"
                />
                <Result
                  value={`${(this.state.results.coverage * 100).toFixed(2)}%`}
                  description="Coverage"
                />
                <Result
                  value={this.state.results.duration}
                  description="Months"
                />
              </SubResultContainer>
            </>
          )
        }
      >
        <NumberInput
          label={`Building SqFt`}
          value={this.state.buildingSf ? this.state.buildingSf.toString() : ''}
          onChange={(val) => {
            this.setState({
              buildingSf: val
            });
          }}
        />
        <AcreageSqFtToggle
          valueType={this.state.unitType}
          label="Site Units"
          onToggle={() => this.toggleUnitTypes()}
        ></AcreageSqFtToggle>
        <NumberInput
          label={`Site ${this.state.unitType === 'sqft' ? 'SqFt' : 'Acreage'}`}
          keyboardType="decimal-pad"
          value={
            this.state.siteSf
              ? this.state.unitType === 'acreage'
                ? (this.state.siteSf / 43560).toFixed(2)
                : this.state.siteSf.toString()
              : ''
          }
          onChange={(val) =>
            this.setState({
              siteSf: this.state.unitType === 'acreage' ? val * 43560 : val
            })
          }
        />
        <NumberInput
          label="Clear Height"
          value={
            this.state.clearHeight ? this.state.clearHeight.toString() : ''
          }
          onChange={(val) => this.setState({ clearHeight: val })}
        />
      </SingleCalculatorScreen>
    );
  }
}

export default NewConstruction;

type State = {
  buildingSf: number;
  siteSf: number;
  clearHeight: number;
  showModal: boolean;
  showResults: boolean;
  unitType: 'sqft' | 'acreage';
  results: {
    costPerSqFt?: number;
    duration?: number;
    coverage?: number;
    totalProjectCost?: number;
  };
};

type Props = {
  navigation: NavigationScreenProp<NavigationState, NavigationParams>;
};
