import { StyleSheet, Platform } from 'react-native';

export const text = StyleSheet.create({
  sansSerif: {
    fontFamily: 'Roboto_400Regular'
  },
  monospace: {
    ...Platform.select({
      ios: {
        fontFamily: 'Courier'
      },
      android: {
        fontFamily: 'monospace'
      }
    })
  }
});

export const input = StyleSheet.create({
  wrapper: {
    borderRadius: 5,
    marginBottom: 10
  },
  label: {
    fontSize: 16,
    opacity: 0.8
  }
});
