import React, { ReactNode } from 'react';
import { TouchableOpacity, View, StyleSheet, Text } from 'react-native';
import { input } from '../../assets/styles';
import { Ionicons } from '@expo/vector-icons';

export default function TrueFalseInput({ label, onChange, checked }: Props) {
  return (
    <TouchableOpacity style={input.wrapper} onPress={() => onChange(!checked)}>
      <View style={styles.row}>
        {checked ? (
          <Ionicons
            name="md-checkbox"
            size={20}
            color="red"
            style={{ marginRight: 10 }}
          ></Ionicons>
        ) : (
          <Ionicons
            name="md-square-outline"
            size={20}
            color="#ddd"
            style={{ marginRight: 10 }}
          ></Ionicons>
        )}

        <Text style={input.label}>{label}</Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  row: {
    paddingHorizontal: 10,
    paddingVertical: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
});

type Props = {
  onChange?(val);
  checked?: boolean;
  label: string;
};
