import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Currency from '../components/Currency';
import { text } from '../assets/styles';

export default function MainResult({ value, description, showResult }: Props) {
  return (
    <View style={{ marginVertical: 20, alignItems: 'center' }}>
      {!isNaN(value) ? (
        <Currency
          style={[styles.mainResultText, text.sansSerif]}
          truncateMillions={true}
          value={value}
          prefix="$"
        />
      ) : (
        <Text>-</Text>
      )}
      <Text style={styles.mainResultDescription}>{description}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  mainResultText: {
    fontSize: 40
  },
  mainResultDescription: {
    fontSize: 12,
    textTransform: 'uppercase'
  }
});

type Props = {
  value: number;
  showResult?: boolean;
  description: string;
};
