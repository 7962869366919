import React, { Component } from 'react';
import { StyleSheet, Text, View, TextInput } from 'react-native';
import Slider from '@react-native-community/slider';
import InputWrapper from './InputWrapper';

class SliderInput extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      _inputvalue: props.value,
      _slidervalue: props.value
    };
    this.handleTextUpdate = this.handleTextUpdate.bind(this);
    this.handleSliderUpdate = this.handleSliderUpdate.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.state._inputvalue !== this.props.value &&
      this.state._slidervalue !== this.props.value
    ) {
      this.handleValueUpdate(this.props.value);
    }
  }

  handleValueUpdate(newValue: number) {
    const value = this.checkMinMaxRange(newValue);
    this.setState({
      _inputvalue: value,
      _slidervalue: value
    });
    // this.props.onChange(value);
  }

  checkMinMaxRange(value: number) {
    if (this.props.minimumValue) {
      // @TODO Trigger modal here this.props.handleOutsideBounds()
      value = value < this.props.minimumValue ? this.props.minimumValue : value;
    }
    if (this.props.maximumValue) {
      // @TODO Trigger modal here
      value = value > this.props.maximumValue ? this.props.maximumValue : value;
    }

    return value;
  }

  handleSliderUpdate(value: number) {
    try {
      this.props.onChange(value);
      this.setState({ _inputvalue: value, _slidervalue: value });
    } catch (e) {}
  }

  handleTextUpdate() {
    const value = this.checkMinMaxRange(this.state._inputvalue);
    try {
      this.props.onChange(value);
      this.setState({ _inputvalue: value, _slidervalue: value });
    } catch (e) {}
  }

  render() {
    const { _slidervalue, _inputvalue } = this.state;

    const {
      label,
      style,
      value,
      minimumValue,
      maximumValue,
      sliderProps,
      onChange,
      disabled,
      ...props
    } = this.props;

    return (
      <InputWrapper
        label={label}
        disabled={disabled}
        headerRight={
          <TextInput
            editable
            maxLength={40}
            autoCompleteType="off"
            keyboardType="number-pad"
            blurOnSubmit={true}
            returnKeyType="done"
            clearTextOnFocus={true}
            style={[styles.input, style]}
            value={_inputvalue ? _inputvalue.toString() : '0'}
            onChange={({ nativeEvent }) => {
              this.setState({ _inputvalue: parseInt(nativeEvent.text) });
            }}
            onEndEditing={() => this.handleTextUpdate()}
            onBlur={() => this.handleTextUpdate()}
            ref="input"
            {...props}
          />
        }
      >
        <Slider
          style={styles.slider}
          value={_slidervalue}
          minimumValue={minimumValue || 0}
          maximumValue={maximumValue || 1}
          minimumTrackTintColor="red"
          maximumTrackTintColor="#000000"
          onValueChange={(value) => this.handleSliderUpdate(parseInt(value))}
          {...sliderProps}
        />
      </InputWrapper>
    );
  }
}

export default SliderInput;

const styles = StyleSheet.create({
  input: {
    borderBottomWidth: 1,
    borderColor: '#000',
    paddingVertical: 2,
    paddingHorizontal: 0,
    fontSize: 16
  },
  slider: {
    width: '100%',
    marginVertical: 2
  },
  track: {
    height: 8,
    borderRadius: 1,
    backgroundColor: '#d5d8e8'
  },
  thumb: {
    width: 20,
    height: 20,
    borderRadius: 1,
    backgroundColor: '#838486'
  }
});

type Props = {
  label: string;
  style?: object;
  value: number;
  minimumValue: number;
  maximumValue: number;
  maxLength?: number;
  sliderProps?: object;
  disabled?: boolean;
  onChange(val: number): void;
};

type State = {
  _slidervalue: number;
  _inputvalue: number;
};
