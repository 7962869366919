import React from 'react';
import { View } from 'react-native';

export default function SubResultContainer({ children }) {
    return (
        <View 
            style={{
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '100%',
                paddingHorizontal: 20
            }}>
            {children}
        </View>
    )
}
