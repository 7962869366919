import React, { ReactNode } from 'react';
import { StyleSheet, Text, View } from 'react-native';

export default function InputWrapper({
  label,
  disabled,
  headerRight,
  children
}: Props) {
  return (
    <View
      style={[styles.wrapper, disabled && styles.disabled]}
      pointerEvents={disabled ? 'none' : 'auto'}
    >
      <View style={styles.header}>
        <Text style={styles.label}>{label}</Text>
        {headerRight}
      </View>

      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    marginHorizontal: 5,
    paddingVertical: 3,
    marginBottom: 10
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  label: {
    fontSize: 14,
    opacity: 0.8,
    textTransform: 'uppercase'
  },
  disabled: {
    opacity: 0.5
  }
});

type Props = {
  label: string;
  headerRight?: ReactNode;
  children?: ReactNode;
  disabled?: boolean;
};
