import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default {
  alert: (title, message, buttons = [], options = {}) => {
    buttons = buttons.map(button => {
      const buttonObjTransformed = {
        label: button.text
      };
      if (button.onPress) {
        buttonObjTransformed.onClick = () => button.onPress();
      }
      return buttonObjTransformed;
    });
    options.closeOnEscape = options.closeOnEscape || options.cancellable;
    options.closeOnClickOutside = options.closeOnEscape || options.cancellable;
    confirmAlert({ title, message, buttons, ...options });
  }
};
