import React, { Component } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Dimensions,
  Image,
  Text,
  StatusBar,
  Platform,
  TouchableOpacity
} from 'react-native';
import {
  NavigationScreenProp,
  NavigationState,
  NavigationParams
} from 'react-navigation';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Alert from '../components/Alert';
import Intro from '../components/Intro';

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');
const overlapOffset = 80;

class LinksScreen extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      onboarded: false
    };
  }

  componentDidMount() {
    this.checkIfOnboarded();
    this._navListener = this.props.navigation.addListener('didFocus', () => {
      StatusBar.setBarStyle('light-content');
      Platform.OS === 'android' && StatusBar.setBackgroundColor('#000');
    });
  }

  componentWillUnmount() {
    this._navListener.remove();
  }

  async checkIfOnboarded() {
    try {
      const value = await AsyncStorage.getItem('USER_HAS_ONBOARDED');
      this.setState({ onboarded: !!value });
    } catch (error) {}
  }

  handleUserOnboardingConfirmation() {
    this.setState({ onboarded: true });
    AsyncStorage.setItem('USER_HAS_ONBOARDED', 'true');
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: '#ffff' }}>
        <StatusBar barStyle="light-content" backgroundColor="#000" />
        <View style={styles.container}>
          <View style={styles.logoContainer}>
            <Image
              source={require('../assets/images/fullmer-logo.png')}
              style={styles.logoImage}
            />
          </View>
          <View style={styles.contentContainer}>
            <View style={styles.content}>
              {this.state.onboarded ? (
                <View style={styles.grid}>
                  <TouchableOpacity
                    style={[styles.optionSingle, styles.optionSingleFull]}
                    onPress={() =>
                      this.props.navigation.navigate('NewConstruction')
                    }
                  >
                    <Image
                      source={require('../assets/images/building-icon-1.png')}
                      style={[styles.optionIcon]}
                    />
                    <Text style={styles.optionSubtext}>Inland Empire</Text>
                    <Text style={styles.optionText}>
                      New Construction Industrial{' '}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[{ opacity: 0.5 }, styles.optionSingle]}
                    onPress={() => {
                      Alert.alert(
                        'Data coming soon.',
                        'We are working to provide you with the latest calculation data for this region.',
                        [
                          {
                            text: 'OK'
                          }
                        ],
                        { cancelable: true }
                      );
                    }}
                  >
                    <Image
                      source={require('../assets/images/building-icon-2.png')}
                      style={[styles.optionIcon]}
                    />
                    <Text style={styles.optionSubtext}>
                      Northern California
                    </Text>
                    <Text style={styles.optionText}>
                      New Construction Industrial
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.optionSingle}
                    onPress={() => this.props.navigation.navigate('MultiStory')}
                  >
                    <Image
                      source={require('../assets/images/building-icon-3.png')}
                      style={[styles.optionIcon]}
                    />
                    <Text style={styles.optionSubtext}>Inland Empire</Text>
                    <Text style={styles.optionText}>Multi-Story Office</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.optionSingle}
                    onPress={() =>
                      this.props.navigation.navigate('TenantImprovements')
                    }
                  >
                    <Image
                      source={require('../assets/images/building-icon-4.png')}
                      style={[styles.optionIcon]}
                    />
                    <Text style={styles.optionSubtext}>Inland Empire</Text>
                    <Text style={styles.optionText}>Tenant Improvements</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.optionSingle}
                    onPress={() => this.props.navigation.navigate('OffSite')}
                  >
                    <Image
                      source={require('../assets/images/building-icon-5.png')}
                      style={[styles.optionIcon]}
                    />
                    <Text style={styles.optionSubtext}>Inland Empire</Text>
                    <Text style={styles.optionText}>Off-Site Improvements</Text>
                  </TouchableOpacity>
                </View>
              ) : (
                <Intro
                  onPressStartCalculating={() =>
                    this.handleUserOnboardingConfirmation()
                  }
                  onPressLearnMore={() =>
                    this.props.navigation.navigate('Home')
                  }
                />
              )}
            </View>
            <TouchableOpacity
              onPress={() => this.props.navigation.navigate('LegalDisclaimer')}
            >
              <Text
                style={{
                  fontSize: 15,
                  marginVertical: 7,
                  textDecorationLine: 'underline'
                }}
              >
                Legal Disclaimer
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    );
  }
}

export default LinksScreen;

LinksScreen.navigationOptions = {
  headerShown: false
};

type Props = {
  navigation: NavigationScreenProp<NavigationState, NavigationParams>;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: overlapOffset,
    backgroundColor: '#000',
    height: SCREEN_HEIGHT / 3,
    position: 'relative',
    zIndex: 0
  },
  logoImage: {
    width: 250,
    height: 80,
    resizeMode: 'contain',
    marginTop: 3,
    marginLeft: -10
  },
  contentContainer: {
    marginTop: -overlapOffset,
    flex: 1,
    alignItems: 'center',
    marginBottom: 5
  },
  content: {
    width: SCREEN_WIDTH * 0.9,
    backgroundColor: '#fff',
    flex: 1,
    borderRadius: 5,
    zIndex: 2,
    position: 'relative',
    padding: 4,
    elevation: 3,
    shadowOffset: { width: 4, height: 4 },
    shadowColor: 'grey',
    shadowOpacity: 0.5,
    shadowRadius: 10,
    marginBottom: 5
  },
  grid: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'space-around'
  },
  optionSingle: {
    width: '50%',
    alignItems: 'center'
  },
  optionSingleFull: {
    width: '100%'
  },
  optionIcon: {
    ...Platform.select({
      web: {
        minHeight: 70,
        minWidth: 70
      }
    }),
    marginBottom: 10
  },
  optionText: {
    fontFamily: 'Roboto_400Regular',
    textAlign: 'center',
    fontWeight: '400',
    color: '#333333'
  },
  optionSubtext: {
    color: 'red',
    textTransform: 'uppercase',
    fontSize: 12
  }
});
