import React, { useState, useEffect } from 'react';
import { TextInput, TextInputProps, StyleSheet, Platform } from 'react-native';
import InputWrapper from './InputWrapper';

export default function NumberInput({
  label,
  disabled = false,
  minimum,
  maximum,
  onChange,
  value,
  keyboardType = 'number-pad',
  ...props
}: Props) {
  const [isBlur, setIsBlur] = useState(true);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    // This prevents issues with parseFloat when adding a decimal place.
    // @link https://stackoverflow.com/questions/42558922/react-onchange-is-swallowing-my-decimal-when-using-parsefloat
    if (
      internalValue.includes('.') &&
      internalValue.indexOf('.') === internalValue.length - 1
    ) {
      return;
    }
    onChange(parseFloat(internalValue));
  }, [internalValue]);

  return (
    <InputWrapper label={label} disabled={disabled}>
      <TextInput
        {...props}
        value={internalValue}
        editable
        maxLength={40}
        autocompletetype="off"
        autoFocus={false}
        keyboardType={keyboardType}
        clearButtonMode="while-editing"
        returnKeyType="done"
        // clearTextOnFocus only seems to work across the board in iOS/Web, spotty in Android
        // We can hack it below with onFocus() handler
        // @link https://github.com/facebook/react-native/issues/19036
        clearTextOnFocus={true}
        onFocus={(event) => {
          if (Platform.OS !== 'web') {
            setInternalValue('');
          }
        }}
        onChange={(event) => setInternalValue(event.nativeEvent.text)}
        onEndEditing={(event) => setInternalValue(event.nativeEvent.text)}
        style={[styles.input, isBlur && styles.inputBlur]}
      />
    </InputWrapper>
  );
}

const styles = StyleSheet.create({
  input: {
    backgroundColor: '#ffffff',
    marginVertical: 8,
    padding: 6,
    borderRadius: 5,
    fontSize: 16,
    width: '100%',
    borderColor: '#ddd',
    borderWidth: 1
  },
  inputBlur: {
    backgroundColor: 'rgba(255,255,255, 0.9)'
  }
});

interface Props extends TextInputProps {
  label: string;
  disabled?: boolean;
  minimum?: number;
  maximum?: number;
  onChange(val);
  onBlur?(event: any): void;
  onFocus?(event: any): void;
}
