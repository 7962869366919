import React from 'react';
import {
  SafeAreaView,
  View,
  StyleSheet,
  Linking,
  Image,
  Text
} from 'react-native';
import Button from '../components/Button';

export default function ContactScreen() {
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#000' }}>
      <View style={styles.container}>
        <View>
          <Image source={require('../assets/images/fullmer-logo.png')} />
        </View>
        <View style={styles.addressGroup}>
          <Text style={styles.address}>1725 South Grove Ave.</Text>
          <Text style={[styles.address, styles.mb]}>Ontario, CA 91761</Text>
          <Text style={styles.address}>Phone: 909.947.9467</Text>
          <Text style={styles.address}>Fax: 909.923.7476</Text>
        </View>
        <View style={styles.buttonGroup}>
          <Button
            title="Call"
            icon="ios-call"
            style={{ marginBottom: 10 }}
            onPress={() => Linking.openURL('tel:9099479467')}
          />
          <Button
            title="Email"
            icon="ios-mail"
            outline
            onPress={() => Linking.openURL('mailto:info@fullmerco.com')}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}

ContactScreen.navigationOptions = {
  headerShown: false
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 100,
    paddingHorizontal: 50
  },
  mb: {
    marginBottom: 15
  },
  buttonGroup: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  addressGroup: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10
  },
  address: {
    color: '#fff',
    fontSize: 20
  }
});
