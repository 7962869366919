import React from 'react';
import * as ReactSvg from 'react-native-svg';

const { Svg, Path } = ReactSvg;

export default function ContactIcon({ focused }) {
  return (
    <Svg viewBox="0 0 30 30" width="25" height="25">
      <Path
        fill={focused ? 'red' : '#333'}
        d="M15.706 7.059a3.493 3.493 0 00-3.176.706 3.769 3.769 0 00-1.412 3 .882.882 0 101.765 0 1.916 1.916 0 01.707-1.584 2.052 2.052 0 011.765-.353 2.086 2.086 0 011.588 1.588 1.928 1.928 0 01-.706 2.118 5.263 5.263 0 00-2.118 4.235.834.834 0 00.882.882c.529 0 .882-.353.706-.882a3.887 3.887 0 011.588-2.824 3.824 3.824 0 001.412-3.882 4 4 0 00-3-3zM14.294 21.353a1.061 1.061 0 00-.176.706.648.648 0 00.176.529 1.338 1.338 0 00.706.353c.176 0 .529-.176.529-.353.176 0 .353-.353.353-.529a.917.917 0 00-.353-.706.853.853 0 00-1.235 0z"
      />
      <Path
        fill="#333"
        d="M15 0a15 15 0 1015 15A14.987 14.987 0 0015 0zm0 28.235A13.332 13.332 0 011.765 15 13.332 13.332 0 0115 1.765 13.332 13.332 0 0128.235 15 13.332 13.332 0 0115 28.235z"
      />
    </Svg>
  );
}
