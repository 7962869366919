import React, { useState } from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';

export default function ToggleInput({ onToggle, valueType, ...props }) {
  return (
    <TouchableOpacity onPress={onToggle}>
      <View style={styles.toggleContainer}>
        <View style={[valueType === 'acreage' && styles.active, styles.toggle]}>
          <Text
            style={[
              styles.label,
              valueType === 'acreage' && styles.labelActive
            ]}
          >
            Ac
          </Text>
          <Text
            style={[
              styles.label,
              styles.superscript,
              valueType === 'acreage' && styles.labelActive
            ]}
          >
            2
          </Text>
        </View>
        <View style={[valueType === 'sqft' && styles.active, styles.toggle]}>
          <Text
            style={[styles.label, valueType === 'sqft' && styles.labelActive]}
          >
            Ft
          </Text>
          <Text
            style={[
              styles.label,
              styles.superscript,
              valueType === 'sqft' && styles.labelActive
            ]}
          >
            2
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  toggleContainer: {
    marginVertical: 5,
    flexDirection: 'row',
    alignSelf: 'flex-start'
  },
  toggle: {
    borderWidth: 2,
    borderColor: '#fff',
    paddingHorizontal: 4,
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  active: {
    backgroundColor: '#fff'
  },
  label: {
    color: '#bbb',
    fontSize: 18,
    lineHeight: 20
  },
  labelActive: {
    color: '#000'
  },
  superscript: {
    fontSize: 18 * 0.6,
    lineHeight: 14,
    textAlignVertical: 'top'
  }
});
