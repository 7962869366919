class CoverageMultiplier {
  public error;

  public value;

  public inputs = {
    siteSf: null,
    buildingSf: null
  };

  private baselineCoverageData;
  private coverageCostAdjustmentData;

  constructor(baselineCoverageData, coverageCostAdjustmentData) {
    this.baselineCoverageData = baselineCoverageData;
    this.coverageCostAdjustmentData = coverageCostAdjustmentData;
  }

  isValidInput(): boolean {
    return (
      !!this.inputs.buildingSf &&
      this.inputs.buildingSf.isBetween(25001, 1250000) &&
      !!this.inputs.siteSf
    );
  }

  coverageIsInRange(): boolean {
    return this.coverage && this.coverage <= 0.6 && this.coverage >= 0.35;
  }

  get baselineCoverage(): number {
    for (const value of this.baselineCoverageData.values) {
      if (this.inputs.buildingSf.isBetween(value.minimum, value.maximum)) {
        return parseFloat(value.output);
      }
    }
  }

  get coverage(): number {
    return Math.round((this.inputs.buildingSf / this.inputs.siteSf) * 100) / 100;
  }

  get coverageDeviation(): number {
    return Math.round((this.coverage - this.baselineCoverage) * 1000) / 1000;
  }

  calculateCostAdjustment(): number {
    if (this.coverageDeviation === 0) return 0;
    for (const value of this.coverageCostAdjustmentData.values) {
      if (this.coverageDeviation.isBetween(value.minimum, value.maximum)) {
        return parseFloat(value.output);
      }
    }
  }

  execute() {
    if (!this.isValidInput()) {
      this.error = 'Coverage could not be calculated based on inputs.';
      throw new Error(this.error);
    }

    if (!this.coverageIsInRange()) {
      this.error = `Calculated coverage of ${this.coverage} is out of range. Coverage must be between 0.35 and 0.6.`;
      throw new Error(this.error);
    }

    this.value = this.calculateCostAdjustment();
    return this.value;
  }
}

export default CoverageMultiplier;
