import React from 'react';
import { Platform, Image } from 'react-native';
import { createStackNavigator } from 'react-navigation-stack';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { Ionicons } from '@expo/vector-icons';

import { CalculatorIcon, AboutIcon, ContactIcon } from '../components/icons';
import AboutScreen from '../screens/AboutScreen';
import AllCalculatorsScreen from '../screens/AllCalculatorsScreen';
import ContactScreen from '../screens/ContactScreen';
import NewConstructionScreen from '../screens/calculators/NewConstructionScreen';
import MultiStoryScreen from '../screens/calculators/MultiStoryScreen';
import TenantImprovementScreen from '../screens/calculators/TenantImprovementScreen';
import OffSiteScreen from '../screens/calculators/OffSiteScreen';
import LegalDisclaimerScreen from '../screens/LegalDisclaimerScreen';
import ShareModalScreen from '../screens/ShareModalScreen';

const config = Platform.select({
  web: {
    headerMode: 'screen',
    headerStyle: {
      backgroundColor: '#000'
    }
  },
  android: {
    headerTitleStyle: { fontFamily: 'Roboto_400Regular' }
  },
  default: {
    defaultNavigationOptions: {
      headerTintColor: '#fff'
    }
  }
});

const AboutStack = createStackNavigator(
  {
    Home: AboutScreen
  },
  config
);

AboutStack.navigationOptions = {
  tabBarLabel: 'About',
  tabBarIcon: ({ focused }) => <AboutIcon focused={focused} />
};

AboutStack.path = '';

const CalculatorsStack = createStackNavigator(
  {
    Calculators: AllCalculatorsScreen,
    NewConstruction: NewConstructionScreen,
    MultiStory: MultiStoryScreen,
    TenantImprovements: TenantImprovementScreen,
    OffSite: OffSiteScreen,
    LegalDisclaimer: LegalDisclaimerScreen
  },
  {
    ...config,
    initialRouteName: 'Calculators',
    defaultNavigationOptions: {
      headerStyle: {
        backgroundColor: '#000000',
        borderBottomWidth: 0
      },
      headerTintColor: '#fff',
      headerTitle: () => (
        <Image
          source={require('../assets/images/fullmer-logo.png')}
          style={{
            resizeMode: 'contain',
            height: 50,
            width: '90%',
            marginLeft: -16
          }}
        />
      )
    }
  }
);

CalculatorsStack.navigationOptions = {
  tabBarLabel: 'Calculators',
  tabBarIcon: ({ focused }) => <CalculatorIcon focused={focused} />
};

CalculatorsStack.path = '';

const ContactStack = createStackNavigator(
  {
    Settings: ContactScreen
  },
  config
);

ContactStack.navigationOptions = {
  tabBarLabel: 'Contact',
  tabBarIcon: ({ focused }) => <ContactIcon focused={focused} />
};

ContactStack.path = '';

const tabNavigator = createBottomTabNavigator(
  {
    AboutStack,
    CalculatorsStack,
    ContactStack
  },
  {
    initialRouteName: 'CalculatorsStack',
    tabBarOptions: {
      activeTintColor: '#333333',
      inactiveTintColor: '#848484',
      keyboardHidesTabBar: true,
      style: {
        backgroundColor: '#FCFCFC',
        height: 70,
        borderTopWidth: 2,
        borderTopColor: '#D3D3D3'
      },
      labelStyle: {
        textTransform: 'uppercase',
        fontWeight: '700'
      },
      tabStyle: {
        paddingVertical: 10
      }
    }
  }
);

tabNavigator.path = '';

// export default tabNavigator;

const RootStack = createStackNavigator(
  {
    tabNavigator: {
      screen: tabNavigator,
      navigationOptions: { headerShown: false }
    },
    ShareModal: {
      screen: ShareModalScreen,
      navigationOptions: {
        headerTitle: '',
        headerBackTitle: null,
        headerTruncatedBackTitle: null,
        headerTransparent: true,
        headerTintColor: '#fff',
        headerBackImage: ({ tintColor }) => (
          <Ionicons name="close-outline" size={45} color={tintColor} />
        )
      }
    }
  },
  {
    mode: 'modal'
  }
);

export default RootStack;
