import React, { Component, ReactNode } from 'react';
import {
  ScrollView,
  StyleSheet,
  View,
  KeyboardAvoidingView,
  Keyboard,
  Platform,
  Text
} from 'react-native';
import {
  NavigationParams,
  NavigationScreenProp,
  NavigationState
} from 'react-navigation';
import Layout from '../../components/Layout';
import CalculatorTitle from '../../components/CalculatorTitle';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import Form from '../../components/Form';
import { FontAwesome } from '@expo/vector-icons';

class SingleCalculatorScreen extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showResult: false,
      modalVisible: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.showModal === true && this.state.modalVisible === false) {
      this.showModal();
    }
  }

  shareResults() {
    const { title, inputsArray, resultsArray } = this.props;
    this.props.navigation.navigate('ShareModal', {
      title,
      resultsArray,
      inputsArray
    });
  }

  showDataFetchErrorModal() {
    Alert.alert(
      'Error Fetching Data',
      'Could not connect to API server to fetch calculation data. Please check your network connection and try again.'
    );
  }

  showModal() {
    this.setState({ modalVisible: true });
    Alert.alert(
      'Whoops!',
      'The inputs you supplied are outside the bounds we can calculate for. Please contact us to discuss the project.',
      [
        {
          text: 'Contact Fullmer',
          onPress: () => {
            this.props.navigation.navigate('ContactStack');
            this.props.onModalClosed();
            this.setState({ modalVisible: false });
          }
        },
        {
          text: 'Cancel',
          onPress: () => {
            this.props.onModalClosed();
            this.setState({ modalVisible: false });
          },
          style: 'cancel'
        }
      ],
      { cancelable: false }
    );
  }

  render() {
    const { results, children, title, subTitle, showResults } = this.props;

    return (
      <Layout showLogo={false}>
        <CalculatorTitle title={title} subTitle={subTitle}></CalculatorTitle>
        {showResults && (
          <View style={styles.results}>
            {results}
            {(this.props.showResults === true ||
              typeof this.props.showResults === 'undefined') && (
              <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <FontAwesome.Button
                  name="share-square"
                  onPress={() => this.shareResults()}
                  size={14}
                  backgroundColor="transparent"
                  color="#333333"
                >
                  {/* Hack employed for OnePlus phones @link https://forums.expo.io/t/text-cut-off-on-oneplus-device/4999/7 */}
                  <Text style={{ fontFamily: 'Roboto_400Regular' }}>
                    {'Share Results  '}
                  </Text>
                </FontAwesome.Button>
              </View>
            )}
          </View>
        )}
        <KeyboardAvoidingView
          style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center'
          }}
          behavior="padding"
          enabled={Platform.OS === 'ios'}
          keyboardVerticalOffset={100}
        >
          {/* https://stackoverflow.com/a/53305687/2611917 */}
          <ScrollView
            style={styles.container}
            contentContainerStyle={styles.contentContainer}
            keyboardShouldPersistTaps="always"
          >
            <Form>{children}</Form>

            <Button
              title={showResults ? 'Recalculate' : 'Calculate Results'}
              style={{ marginBottom: 10 }}
              onPress={() => {
                Keyboard.dismiss();
                this.props.onCalculate();
              }}
            />
          </ScrollView>
        </KeyboardAvoidingView>
      </Layout>
    );
  }
}

export default SingleCalculatorScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  contentContainer: {
    paddingHorizontal: 10
  },
  results: {
    paddingBottom: 5,
    marginBottom: 5,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd'
  },
  pinnedBottomButton: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingHorizontal: 10
  }
});

type State = {
  showResult: boolean;
  modalVisible: boolean;
};

type Props = {
  navigation: NavigationScreenProp<NavigationState, NavigationParams>;
  results?: ReactNode;
  showModal?: boolean;
  showResults?: boolean;
  onModalClosed?();
  onCalculate?();
  resultsArray: object;
  inputsArray: object;
  title: string;
  subTitle?: string;
};
