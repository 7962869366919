import React, { Component } from 'react';
import {
  NavigationParams,
  NavigationScreenProp,
  NavigationState
} from 'react-navigation';
import SubResultContainer from '../../components/SubResultContainer';
import { formatCurrency } from '../../helpers';
import { TrueFalseInput, NumberInput } from '../../components/inputs';
import SingleCalculatorScreen from './SingleCalculatorScreen';
import MainResult from '../../components/MainResult';
import Result from '../../components/Result';
import MultiStoryCalculator from '../../calculators/MultiStory';

class MultiStory extends Component<Props, State> {
  calculator: MultiStoryCalculator;

  constructor(props) {
    super(props);
    this.calculator = new MultiStoryCalculator();

    this.state = {
      buildingFootprint: 25000,
      stories: 1,
      warmShell: false,
      tiSf: 0,
      showModal: false,
      showResults: false,
      showTi: false,
      results: {
        totalProjectCost: 0,
        shellCost: 0,
        warmShellCost: 0,
        tiCost: 0,
        costPerSqFt: 0,
        duration: 0,
        totalSf: 0
      }
    };
  }

  calculateResults() {
    const { buildingFootprint, stories, warmShell, tiSf } = this.state;

    try {
      this.calculator
        .setBuildingFootprint(buildingFootprint)
        .setStories(stories)
        .setWarmShell(warmShell)
        .setTiSf(tiSf);

      const results = this.calculator.calculate();

      this.setState({ results: { ...results }, showResults: true });
    } catch (e) {
      this.setState({ showModal: true, showResults: false });
    }
  }

  getInputsArray() {
    return [
      {
        label: 'Building Footprint',
        value: `${Number(this.state.buildingFootprint).toLocaleString()} sqft`
      },
      {
        label: 'Stories',
        value: this.state.stories
      },
      {
        label: 'Include Warm Shell',
        value: this.state.warmShell ? 'YES' : 'NO'
      },
      {
        label: 'TI SF',
        value:
          this.state.showTi && this.state.tiSf > 0
            ? Number(this.state.tiSf).toLocaleString()
            : 'N/A'
      }
    ];
  }

  getResultsArray() {
    let results = [
      {
        label: 'Total Project Cost',
        value: formatCurrency(this.state.results.totalProjectCost)
      },
      {
        label: 'Shell Cost',
        value: formatCurrency(this.state.results.shellCost)
      },
      {
        label: 'Warm Shell Cost',
        value: this.state.warmShell
          ? formatCurrency(this.state.results.warmShellCost)
          : 'No'
      }
    ];

    if (this.state.showTi && this.state.tiSf > 0) {
      results.push(
        {
          label: 'TI SF',
          value: this.state.showTi ? Number(this.state.tiSf).toString() : 'N/A'
        },
        {
          label: 'TI Cost',
          value: this.state.showTi
            ? formatCurrency(this.state.results.tiCost)
            : 'N/A'
        }
      );
    }

    results.push(
      {
        label: 'Cost per SqFt',
        value: formatCurrency(this.state.results.costPerSqFt)
      },
      {
        label: 'Duration',
        value: `${this.state.results.duration} months`
      }
    );

    return results;
  }

  render() {
    const {
      buildingFootprint,
      stories,
      warmShell,
      showTi,
      tiSf,
      showResults,
      showModal
    } = this.state;
    return (
      <SingleCalculatorScreen
        title="Multi-Story Office"
        subTitle="Inland Empire"
        navigation={this.props.navigation}
        inputsArray={this.getInputsArray()}
        resultsArray={this.getResultsArray()}
        showModal={showModal}
        showResults={showResults}
        onModalClosed={() => this.setState({ showModal: false })}
        onCalculate={() => this.calculateResults()}
        results={
          this.state.showResults && (
            <>
              <MainResult
                value={this.calculator.totalProjectCost}
                description="Total Project Cost"
              />
              <SubResultContainer>
                <Result
                  value={this.calculator.shellCost}
                  currency={true}
                  description="Shell Cost"
                />
                {warmShell && (
                  <Result
                    value={this.calculator.warmShellCost}
                    currency={true}
                    description="Warm Shell"
                  />
                )}
                {showTi && tiSf > 0 && (
                  <Result
                    value={this.calculator.tiCost}
                    currency={true}
                    description="TI Cost"
                  />
                )}
                <Result
                  value={`$${this.calculator.costPerSqFt.toFixed(2)}`}
                  description="Cost per sqft"
                />
                <Result value={this.calculator.duration} description="Months" />
              </SubResultContainer>
            </>
          )
        }
      >
        <NumberInput
          label="Building Footprint"
          value={buildingFootprint.toString()}
          onChange={(val) =>
            this.setState({ buildingFootprint: val, showResults: false })
          }
          maxLength={7}
        />
        <NumberInput
          label="Stories"
          value={stories.toString()}
          onChange={(val) =>
            this.setState({ stories: val, showResults: false })
          }
          maxLength={1}
        />
        <TrueFalseInput
          checked={warmShell}
          label="Include Warm Shell?"
          onChange={(val) => {
            let state: any = { warmShell: val, showResults: false };
            if (val === false) {
              state.tiSf = 0;
            }
            this.setState(state);
          }}
        />
        {warmShell && (
          <TrueFalseInput
            checked={showTi}
            label="Add TI"
            onChange={(val) =>
              this.setState({ showTi: val, showResults: false })
            }
          />
        )}
        {warmShell && showTi && (
          <NumberInput
            label="TI SF"
            value={tiSf !== 0 ? tiSf.toString() : '0'}
            onChange={(val) =>
              this.setState({ tiSf: parseInt(val), showResults: false })
            }
          />
        )}
      </SingleCalculatorScreen>
    );
  }
}

export default MultiStory;

type State = {
  buildingFootprint: number;
  stories: number;
  warmShell: boolean;
  tiSf: number;
  showTi: boolean;
  showModal: boolean;
  showResults: boolean;
  results: {
    totalProjectCost: number;
    shellCost: number;
    warmShellCost: number;
    tiCost: number;
    costPerSqFt: number;
    duration: number;
    totalSf: number;
  };
};

type Props = {
  navigation: NavigationScreenProp<NavigationState, NavigationParams>;
};
