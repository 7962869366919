import AsyncStorage from '@react-native-async-storage/async-storage';

class Calculator {
  // Outputs
  public totalProjectCost;
  public costPerSqFt;
  public duration;

  private inputs = {
    buildingSf: null,
    tiSf: null,
    mezzanineSf: null,
    scrimFoil: null,
    ledLighting: null,
    dockEquipment: {} as DockEquipment,
    dockAccessories: [] as DockAccessories[]
  };

  private baselineCostData;
  private lsAddTiPowerDistributionData;
  private baseDurationData;
  private mezzSfBaseCostData;
  private lsAddForElevatorData;
  private mezzAddDurationData;
  private scrimFoilInsulationData;
  private ledLightingData;
  private dockItemsData;

  constructor() {
    AsyncStorage.getItem('CALC_DATA').then(data => {
      const json = JSON.parse(data);
      const {
        tenantImprovementsInland: { dimensions, items }
      } = json;

      this.baselineCostData = dimensions.baseCost;
      this.lsAddTiPowerDistributionData = dimensions.lsAddTIPowerDistribution;
      this.baseDurationData = dimensions.baseDuration;
      this.mezzSfBaseCostData = dimensions.mezzSfBaseCost;
      this.lsAddForElevatorData = dimensions.lsAddForElevator;
      this.mezzAddDurationData = dimensions.mezzAddDuration;
      this.scrimFoilInsulationData = dimensions.scrimFoilInsulation;
      this.ledLightingData = dimensions.ledLighting;

      this.dockItemsData = items;
    });
  }

  isValidInput() {
    return (
      this.inputs.buildingSf &&
      this.inputs.buildingSf.isBetween(500, 2000000) &&
      this.inputs.tiSf &&
      this.inputs.tiSf.isBetween(500, 2000000) &&
      (this.inputs.mezzanineSf === 0 ||
        this.inputs.mezzanineSf.isBetween(1000, 1000000)) &&
      this.inputs.dockEquipment.quantity <= 250
    );
  }

  setBuildingSf(value: number) {
    this.inputs.buildingSf = value;
    return this;
  }

  setTiSf(value: number) {
    this.inputs.tiSf = value;
    return this;
  }

  setMezzanineSf(value: number) {
    this.inputs.mezzanineSf = value;
    return this;
  }

  setScrimFoil(value: boolean) {
    this.inputs.scrimFoil = value;
    return this;
  }

  setLedLighting(value: boolean) {
    this.inputs.ledLighting = value;
    return this;
  }

  setDockEquipment(value: { type?: DockEquipmentType; quantity: number }) {
    this.inputs.dockEquipment = value;
    return this;
  }

  setDockAccessories(value: DockAccessories[]) {
    this.inputs.dockAccessories = value;
    return value;
  }

  get baselineCostPerSqFt() {
    for (const value of this.baselineCostData.values) {
      if (this.inputs.tiSf.isBetween(value.minimum, value.maximum)) {
        return parseFloat(value.output);
      }
    }
  }

  get baseDuration() {
    for (const value of this.baseDurationData.values) {
      if (this.inputs.tiSf.isBetween(value.minimum, value.maximum)) {
        return parseFloat(value.output);
      }
    }
  }

  get mezzanineCostPerSqFt() {
    if (!this.inputs.mezzanineSf) return 0;
    for (const value of this.mezzSfBaseCostData.values) {
      if (this.inputs.mezzanineSf.isBetween(value.minimum, value.maximum)) {
        return parseFloat(value.output);
      }
    }
  }

  get mezzanineDurationAdd() {
    if (!this.inputs.mezzanineSf) return 0;
    for (const value of this.mezzAddDurationData.values) {
      if (this.inputs.mezzanineSf.isBetween(value.minimum, value.maximum)) {
        return parseFloat(value.output);
      }
    }
  }

  get scrimFoilCostPerSqFt() {
    if (!this.inputs.scrimFoil) return 0;
    for (const value of this.scrimFoilInsulationData.values) {
      if (this.addOnSf.isBetween(value.minimum, value.maximum)) {
        return parseFloat(value.output);
      }
    }
  }

  get ledLightingCostPerSqFt() {
    if (!this.inputs.ledLighting) return 0;
    for (const value of this.ledLightingData.values) {
      if (this.addOnSf.isBetween(value.minimum, value.maximum)) {
        return parseFloat(value.output);
      }
    }
  }

  get dockEquipmentUnitCost() {
    if (!this.inputs.dockEquipment || !this.inputs.dockEquipment.type) return 0;
    return this.dockItemsData[this.inputs.dockEquipment.type].cost;
  }

  get dockAccessoriesCost() {
    if (!this.inputs.dockAccessories || !this.inputs.dockAccessories.length)
      return 0;
    const selectedItems = Object.keys(this.dockItemsData)
      .filter(item => this.inputs.dockAccessories.includes(item))
      .reduce((prev, key) => {
        return prev + parseFloat(this.dockItemsData[key].cost);
      }, 0);

    return selectedItems;
  }

  get addOnSf() {
    if (this.inputs.mezzanineSf) {
      return this.inputs.buildingSf - this.inputs.mezzanineSf;
    }
    return this.inputs.buildingSf - this.inputs.tiSf;
  }

  calculate() {
    if (!this.isValidInput()) {
      throw new Error('Invalid Input');
    }

    const baseCost = this.baselineCostPerSqFt * this.inputs.tiSf;
    const mezzCost = this.mezzanineCostPerSqFt * this.inputs.mezzanineSf;
    const scrimFoilCost = this.scrimFoilCostPerSqFt * this.addOnSf;
    const ledLightingCost = this.ledLightingCostPerSqFt * this.addOnSf;
    const dockEquipmentCost =
      this.dockEquipmentUnitCost * this.inputs.dockEquipment.quantity;
    const dockAccessoriesCost =
      this.dockAccessoriesCost * this.inputs.dockEquipment.quantity;
    this.totalProjectCost =
      baseCost +
      mezzCost +
      scrimFoilCost +
      ledLightingCost +
      dockEquipmentCost +
      dockAccessoriesCost;
    this.costPerSqFt = this.totalProjectCost / this.inputs.buildingSf;
    this.duration = this.baseDuration + this.mezzanineDurationAdd;
    return {
      baseCost: baseCost,
      baselineCostPerSqFt: this.baselineCostPerSqFt,
      mezzanineCostPerSqFt: this.mezzanineCostPerSqFt,
      scrimFoilCost: scrimFoilCost,
      ledLightingCost: ledLightingCost,
      dockEquipmentCost: dockEquipmentCost + dockAccessoriesCost,
      totalProjectCost: this.totalProjectCost,
      costPerSqFt: this.costPerSqFt,
      duration: this.duration
    };
  }
}

export default Calculator;

export interface TenantImprovementInputValues {
  buildingSf: number;
  tiSf?: number;
  mezzanineSf?: number;
  scrimFoil?: boolean;
  ledLighting?: boolean;
  dockEquipment: DockEquipment;
  dockAccessories?: DockAccessories[];
}

type DockEquipment = {
  quantity: number;
  type?: DockEquipmentType;
};

export enum DockEquipmentType {
  edgeOfDock = 'Edge of Dock',
  mechanicalLevers = 'Mechanical Levers w/ Bumpers',
  hydraulicBumpers = 'Hydraulic 35k w/ Bumpers'
}

export enum DockAccessories {
  dockSeals = 'dockSeals',
  vehicleRestraint = 'vehicleRestraint'
}
