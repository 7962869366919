import AsyncStorage from '@react-native-async-storage/async-storage';

class Calculator {
  // Outputs
  public totalProjectCost;
  public costPerLinealFt;

  private inputs = {
    linealFt: null,
    streetWidth: StreetWidth.Full,
    utilities: Utilities.New
  };

  private itemCostData;

  constructor() {
    AsyncStorage.getItem('CALC_DATA').then(data => {
      const json = JSON.parse(data);
      const {
        offSiteImprovementsInland: { items }
      } = json;

      this.itemCostData = items;
    });
  }

  isValidInput() {
    return (
      this.inputs.linealFt &&
      this.inputs.linealFt >= 100 &&
      (this.inputs.streetWidth === StreetWidth.Full ||
        (this.inputs.streetWidth === StreetWidth.Half && this.inputs.utilities))
    );
  }

  setLinealFt(value: number) {
    this.inputs.linealFt = value;
    return this;
  }

  setStreetWidth(value: StreetWidth) {
    this.inputs.streetWidth = value;
    return this;
  }

  setUtilties(value: Utilities) {
    this.inputs.utilities = value;
    return this;
  }

  calculate() {
    if (!this.isValidInput()) {
      console.error('Invalid input', this.inputs);
      throw new Error('Invalid input');
    }

    if (this.inputs.streetWidth === StreetWidth.Full) {
      this.costPerLinealFt = this.itemCostData.fullWidthNewUtilities.cost;
    } else if (this.inputs.utilities === Utilities.New) {
      this.costPerLinealFt = this.itemCostData.halfWidthNewUtilities.cost;
    } else {
      this.costPerLinealFt = this.itemCostData.halfWidthExistingUtilities.cost;
    }

    this.totalProjectCost = this.costPerLinealFt * this.inputs.linealFt;

    return {
      totalProjectCost: this.totalProjectCost,
      costPerLinealFt: this.costPerLinealFt
    };
  }
}

export default Calculator;

export interface InputValues {
  linealFt: number;
  utilities: Utilities;
  streetWidth: StreetWidth;
}

export enum StreetWidth {
  Full = 'Full',
  Half = 'Half'
}

export enum Utilities {
  New = 'New',
  Existing = 'Existing'
}
