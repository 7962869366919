import AsyncStorage from '@react-native-async-storage/async-storage';

class Calculator {
  // Outputs
  public totalProjectCost;
  public shellCost;
  public warmShellCost;
  public tiCost;
  public costPerSqFt;
  public duration;

  private inputs = {
    buildingFootprint: null,
    stories: null,
    warmShell: false,
    tiSf: null
  };

  private baselineCostData;
  private baselineDurationData;
  private storiesSfAddData;
  private storiesDurationAddData;
  private warmShellSqFtCostData;
  private warmShellDurationData;
  private tiSqFtCostData;
  private tiDurationData;

  constructor() {
    AsyncStorage.getItem('CALC_DATA').then(data => {
      const json = JSON.parse(data);
      const { multiStoryOfficeInland } = json;
      const { dimensions } = multiStoryOfficeInland;

      this.baselineCostData = dimensions.baseCost;
      this.baselineDurationData = dimensions.baseDuration;
      this.storiesSfAddData = dimensions.storiesSfAdd;
      this.storiesDurationAddData = dimensions.storiesDurationAdd;
      this.warmShellSqFtCostData = dimensions.warmShellSqFtCost;
      this.warmShellDurationData = dimensions.warmShellDuration;
      this.tiSqFtCostData = dimensions.tiSqFtCost;
      this.tiDurationData = dimensions.tiDuration;
    });
  }

  isValidInput() {
    return (
      this.inputs.buildingFootprint &&
      this.inputs.buildingFootprint.isBetween(5000, 59999) &&
      this.inputs.stories &&
      this.inputs.stories.isBetween(1, 5) &&
      typeof this.inputs.warmShell === 'boolean' &&
      (this.inputs.tiSf === 0 || this.inputs.tiSf.isBetween(2000, 1000000))
    );
  }

  setBuildingFootprint(value: number) {
    this.inputs.buildingFootprint = value;
    return this;
  }

  setStories(value: number) {
    this.inputs.stories = value;
    return this;
  }

  setWarmShell(value: boolean) {
    this.inputs.warmShell = value;
    return this;
  }

  setTiSf(value: number) {
    this.inputs.tiSf = value;
    return this;
  }

  get baselineCostPerSqFt() {
    for (const value of this.baselineCostData.values) {
      if (
        this.inputs.buildingFootprint.isBetween(value.minimum, value.maximum)
      ) {
        return parseFloat(value.output);
      }
    }
  }

  get baseDuration() {
    for (const value of this.baselineDurationData.values) {
      if (
        this.inputs.buildingFootprint.isBetween(value.minimum, value.maximum)
      ) {
        return parseFloat(value.output);
      }
    }
  }

  get storiesCost(): number {
    let cost: number;
    for (const value of this.storiesSfAddData.values) {
      if (this.inputs.stories === value.minimum) {
        cost = parseFloat(value.output);
      }
    }
    return this.inputs.buildingFootprint * cost;
  }

  get storiesDurationAdd(): number {
    for (const value of this.storiesDurationAddData.values) {
      if (this.inputs.stories === value.minimum) {
        return parseFloat(value.output);
      }
    }
  }

  get warmShellCostAdd(): number {
    if (!this.inputs.warmShell) return 0;
    let cost: number;
    for (const value of this.warmShellSqFtCostData.values) {
      if (this.totalSf.isBetween(value.minimum, value.maximum)) {
        cost = parseFloat(value.output);
      }
    }
    return cost;
  }

  get warmShellDurationAdd(): number {
    if (!this.inputs.warmShell) return 0;
    for (const value of this.warmShellDurationData.values) {
      if (this.totalSf.isBetween(value.minimum, value.maximum)) {
        return parseFloat(value.output);
      }
    }
  }

  get tiSfCostAdd(): number {
    if (!this.inputs.tiSf) return 0;
    let cost: number;
    for (const value of this.tiSqFtCostData.values) {
      if (this.inputs.tiSf.isBetween(value.minimum, value.maximum)) {
        cost = parseFloat(value.output);
      }
    }
    return cost;
  }

  get tiSfDurationAdd(): number {
    if (!this.inputs.tiSf) return 0;
    for (const value of this.tiDurationData.values) {
      if (this.inputs.tiSf.isBetween(value.minimum, value.maximum)) {
        return parseFloat(value.output);
      }
    }
  }

  get totalSf(): number {
    return this.inputs.buildingFootprint * this.inputs.stories;
  }

  calculate() {
    if (!this.isValidInput()) {
      throw new Error('Invalid input');
    }
    this.costPerSqFt = this.baselineCostPerSqFt;

    const baseCost = this.inputs.buildingFootprint * this.baselineCostPerSqFt;

    this.shellCost = baseCost + this.storiesCost;
    this.warmShellCost = this.totalSf * this.warmShellCostAdd;
    this.tiCost = this.inputs.tiSf * this.tiSfCostAdd;
    this.duration =
      this.baseDuration +
      this.storiesDurationAdd +
      this.warmShellDurationAdd +
      this.tiSfDurationAdd;

    this.totalProjectCost = this.shellCost + this.warmShellCost + this.tiCost;
    this.costPerSqFt = this.totalProjectCost / this.totalSf;

    return {
      totalProjectCost: this.totalProjectCost,
      shellCost: this.shellCost,
      warmShellCost: this.warmShellCost,
      tiCost: this.tiCost,
      costPerSqFt: this.costPerSqFt,
      duration: this.duration,
      totalSf: this.totalSf
    };
  }
}
export default Calculator;
