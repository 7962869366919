import React, { Component } from 'react';
import {
  NavigationParams,
  NavigationScreenProp,
  NavigationState
} from 'react-navigation';
import { formatCurrency } from '../../helpers';
import SubResultContainer from '../../components/SubResultContainer';
import {
  SliderInput,
  TrueFalseInput,
  PickerInput
} from '../../components/inputs';
import SingleCalculatorScreen from './SingleCalculatorScreen';
import MainResult from '../../components/MainResult';
import Result from '../../components/Result';
import TenantImprovementCalculator, {
  TenantImprovementInputValues,
  DockEquipmentType,
  DockAccessories
} from '../../calculators/TenantImprovement';

class TenantImprovementScreen extends Component<Props, State> {
  calculator: TenantImprovementCalculator;

  constructor(props) {
    super(props);
    this.calculator = new TenantImprovementCalculator();
    this.state = {
      buildingSf: 150000,
      tiSf: 5000,
      mezzanineSf: 2500,
      scrimFoil: false,
      ledLighting: false,
      dockEquipment: {
        type: 'edgeOfDock',
        quantity: 0
      },
      dockAccessories: [],
      showResults: false,
      showModal: false,
      showDockEquipment: false,
      results: {
        totalProjectCost: 0,
        costPerSqFt: 0,
        duration: 0
      }
    };
  }

  toggleDockAccessory(value) {
    const { dockAccessories } = this.state;
    const index = dockAccessories.indexOf(value);
    index === -1 ? dockAccessories.push(value) : dockAccessories.splice(index);
    this.setState({ dockAccessories });
  }

  toggleDockEquipment() {
    const dockEquipmentReset = {
      type: 'edgeOfDock',
      quantity: this.state.showDockEquipment ? 0 : 1
    };
    this.setState({
      dockEquipment: dockEquipmentReset,
      showDockEquipment: !this.state.showDockEquipment
    });
  }

  calculateResults() {
    const {
      buildingSf,
      mezzanineSf,
      tiSf,
      scrimFoil,
      ledLighting,
      dockEquipment,
      dockAccessories
    } = this.state;
    try {
      this.calculator
        .setBuildingSf(buildingSf)
        .setTiSf(tiSf)
        .setMezzanineSf(mezzanineSf)
        .setScrimFoil(scrimFoil)
        .setLedLighting(ledLighting)
        .setDockEquipment(dockEquipment)
        .setDockAccessories(dockAccessories);

      const results = this.calculator.calculate();
      this.setState({ results: { ...results }, showResults: true });
    } catch (e) {
      this.setState({ showModal: true, showResults: false });
    }
  }

  getInputsArray() {
    const array = [
      {
        label: 'Building SqFt',
        value: Number(this.state.buildingSf).toLocaleString()
      },
      {
        label: 'TI SqFt',
        value: Number(this.state.tiSf).toLocaleString()
      },
      {
        label: 'Mezzanine SqFt',
        value: Number(this.state.mezzanineSf).toLocaleString()
      },
      {
        label: 'Scrim Foil Insulation',
        value: this.state.scrimFoil ? 'Yes' : 'No'
      },
      {
        label: 'LED Warehouse Lighting',
        value: this.state.ledLighting ? 'Yes' : 'No'
      },
      {
        label: 'Dock Equipment Type',
        value: this.state.showDockEquipment
          ? this.state.dockEquipment.type
          : 'None'
      }
    ];
    if (this.state.showDockEquipment) {
      array.push(
        {
          label: 'Dock Equipment Quantity',
          value: this.state.dockEquipment.quantity.toString()
        },
        {
          label: 'Dock Seals',
          value: this.state.dockAccessories.includes(DockAccessories.dockSeals)
            ? 'Yes'
            : 'No'
        },
        {
          label: 'Vehicle Restraint w/ Red/Green Light',
          value: this.state.dockAccessories.includes(
            DockAccessories.vehicleRestraint
          )
            ? 'Yes'
            : 'No'
        }
      );
    }

    return array;
  }

  getResultsArray() {
    let array = [
      {
        label: 'Cost of office improvements',
        value: formatCurrency(this.state.results.baseCost)
      },
      {
        label: 'Office improvements SF',
        value: formatCurrency(this.state.results.baselineCostPerSqFt)
      }
    ];

    if (this.state.mezzanineSf > 0) {
      array.push({
        label: 'Mezzanine cost SF',
        value: formatCurrency(this.state.results.mezzanineCostPerSqFt)
      });
    }

    if (this.state.scrimFoil) {
      array.push({
        label: 'Scrim Foil Insulation',
        value: formatCurrency(this.state.results.scrimFoilCost)
      });
    }

    if (this.state.ledLighting) {
      array.push({
        label: 'Warehouse lights',
        value: formatCurrency(this.state.results.ledLightingCost)
      });
    }

    array.push(
      {
        label: 'Dock equipment costs',
        value: formatCurrency(this.state.results.dockEquipmentCost)
      },
      {
        label: 'Total Project Cost',
        value: formatCurrency(this.state.results.totalProjectCost)
      },
      {
        label: 'Cost per SqFt',
        value: formatCurrency(this.state.results.costPerSqFt)
      },
      {
        label: 'Duration',
        value: `${this.state.results.duration} months`
      }
    );

    return array;
  }

  render() {
    const {
      buildingSf,
      tiSf,
      mezzanineSf,
      showDockEquipment,
      dockEquipment,
      dockAccessories,
      scrimFoil,
      ledLighting,
      showResults,
      showModal
    } = this.state;
    return (
      <SingleCalculatorScreen
        title="Tenant Improvement"
        subTitle="Inland Empire"
        navigation={this.props.navigation}
        inputsArray={this.getInputsArray()}
        resultsArray={this.getResultsArray()}
        showModal={showModal}
        showResults={showResults}
        onModalClosed={() => this.setState({ showModal: false })}
        onCalculate={() => this.calculateResults()}
        results={
          this.state.showResults && (
            <>
              <MainResult
                value={this.calculator.totalProjectCost}
                description="Total Project Cost"
              />
              <SubResultContainer>
                <Result
                  value={`$${this.calculator.costPerSqFt.toFixed(2)}`}
                  description="Cost per sqft"
                />
                <Result value={this.calculator.duration} description="Months" />
              </SubResultContainer>
            </>
          )
        }
      >
        <SliderInput
          label="Building SF"
          value={buildingSf}
          onChange={(val) => this.setState({ buildingSf: val })}
          maxLength={7}
          minimumValue={500}
          maximumValue={2000000}
          sliderProps={{
            step: 1000
          }}
        />
        <SliderInput
          label="TI SF"
          value={tiSf}
          onChange={(val) => this.setState({ tiSf: val })}
          maxLength={6}
          minimumValue={500}
          maximumValue={10000}
          sliderProps={{
            step: 100
          }}
        />
        <SliderInput
          label="Mezzanine SF"
          value={mezzanineSf}
          onChange={(val) => this.setState({ mezzanineSf: val })}
          maxLength={7}
          minimumValue={0}
          maximumValue={100000}
          sliderProps={{
            step: 1000
          }}
        />

        <TrueFalseInput
          checked={scrimFoil}
          label="Add Scrim Foil Insulation"
          onChange={(val) => this.setState({ scrimFoil: val })}
        />

        <TrueFalseInput
          checked={ledLighting}
          label="Add LED Warehouse Lighting"
          onChange={(val) => this.setState({ ledLighting: val })}
        />

        <TrueFalseInput
          checked={showDockEquipment}
          label="Add Dock Equipment"
          onChange={(val) => this.toggleDockEquipment()}
        />
        {showDockEquipment && (
          <>
            <PickerInput
              label="Equipment Type"
              options={Object.entries(DockEquipmentType).map((type) => ({
                label: type[1],
                value: type[0]
              }))}
              value={dockEquipment.type}
              onValueChange={(val) =>
                this.setState({
                  dockEquipment: {
                    ...dockEquipment,
                    type: val
                  }
                })
              }
            />

            <SliderInput
              label="Quantity"
              value={dockEquipment.quantity}
              onChange={(val) =>
                this.setState({
                  dockEquipment: {
                    ...dockEquipment,
                    quantity: val
                  }
                })
              }
              maxLength={2}
              minimumValue={0}
              maximumValue={250}
              sliderProps={{
                step: 1
              }}
            />

            <TrueFalseInput
              checked={dockAccessories.includes(DockAccessories.dockSeals)}
              label="Add Dock Seals"
              onChange={() =>
                this.toggleDockAccessory(DockAccessories.dockSeals)
              }
            />

            <TrueFalseInput
              checked={dockAccessories.includes(
                DockAccessories.vehicleRestraint
              )}
              label="Add Vehicle Restraint w/ Red/Green Light"
              onChange={() =>
                this.toggleDockAccessory(DockAccessories.vehicleRestraint)
              }
            />
          </>
        )}
      </SingleCalculatorScreen>
    );
  }
}

export default TenantImprovementScreen;

type BaseState = {
  showResults: boolean;
  showModal: boolean;
  showDockEquipment: boolean;
  results: {
    baseCost: number;
    baselineCostPerSqFt: number;
    mezzanineCostPerSqFt: number;
    scrimFoilCost: number;
    ledLightingCost: number;
    dockEquipmentCost: number;
    totalProjectCost: number;
    costPerSqFt: number;
    duration: number;
  };
};

type State = TenantImprovementInputValues & BaseState;

type Props = {
  navigation: NavigationScreenProp<NavigationState, NavigationParams>;
};
