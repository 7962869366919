import React from 'react';
import * as ReactSvg from 'react-native-svg';

const { Svg, Path } = ReactSvg;

export default function CalculatorIcon({ focused }) {
  return (
    <Svg viewBox="0 0 30 30" width="25" height="25">
      <Path
        fill={focused ? 'red' : '#333'}
        d="M10.862 7.238H8.276V4.655a.517.517 0 10-1.034 0v2.583H4.655a.517.517 0 100 1.034h2.583v2.586a.517.517 0 101.034 0V8.276h2.586a.517.517 0 100-1.034zM25.345 7.241h-6.207a.517.517 0 000 1.034h6.207a.517.517 0 000-1.034z"
      />
      <Path
        d="M15.517 0H0v30h30V0zM1.034 1.034h13.449v13.449H1.034zm0 27.931V15.517h13.449v13.449zm27.931 0H15.517V15.517h13.449zM15.517 14.483V1.034h13.449v13.449z"
        fill="#333"
      />
      <Path
        fill={focused ? 'red' : '#333'}
        d="M19.138 24.31h6.207a.517.517 0 000-1.034h-6.207a.517.517 0 000 1.034zM19.138 21.206h6.207a.517.517 0 000-1.034h-6.207a.517.517 0 000 1.034zM10.71 19.289a.517.517 0 00-.731 0L7.755 21.51l-2.217-2.221a.517.517 0 10-.731.731l2.22 2.218-2.221 2.224a.517.517 0 10.731.731l2.218-2.22 2.221 2.221a.517.517 0 00.731-.731L8.49 22.238l2.221-2.221a.517.517 0 00-.001-.728z"
      />
    </Svg>
  );
}
