import React, { useState, useEffect } from 'react';
import {
  SafeAreaView,
  View,
  Text,
  Image,
  StyleSheet,
  Platform
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import email from 'react-native-email';
import * as MailComposer from 'expo-mail-composer';
import Button from '../components/Button';

export default function ({ navigation }) {
  const title = navigation.getParam('title');
  const inputsArray = navigation.getParam('inputsArray', []);
  const resultsArray = navigation.getParam('resultsArray', []);
  const date = new Date();
  const [legal, setLegal] = useState({ ops: [] });

  useEffect(() => {
    AsyncStorage.getItem('CONTENT_DATA').then((data) => {
      const json = JSON.parse(data);
      setLegal(json.legal.content_delta);
    });
  }, []);

  const getEmailBody = () => {
    let body =
      '<strong>Fullmer Construction Cost Calculation Result:</strong><br /><br />';
    body += 'Input values:<br />';
    body += '--------------------<br />';
    body += inputsArray
      .map(({ value, label }) => `<strong>${label}:</strong> ${value}`)
      .join('<br />');
    body += '<br /><br />';
    body += 'Results:<br />';
    body += '--------------------<br />';
    body += resultsArray
      .map(({ value, label }) => `<strong>${label}:</strong> ${value}`)
      .join('<br />');

    body += `<br /><br />--------------------<br />`;
    body += `Disclaimer: ${legal.ops.map((op) => op.insert).join(' ')}`;
    body += `<br /><br />--------------------<br />`;
    body += `<br /><br />Generated on: ${date.toString()}`;
    return body;
  };

  const sendToEmail = async () => {
    // Expo MailComposer doesn't work with HTML properly on Android,
    // so we only use when on iOS
    const isMailComposerAvailable = await MailComposer.isAvailableAsync();
    if (Platform.OS === 'ios' && isMailComposerAvailable) {
      MailComposer.composeAsync({
        subject: `[Fullmer Construction] ${title} Estimate`,
        body: getEmailBody(),
        isHtml: true
      });
    } else {
      email([], {
        subject: `[Fullmer Construction] ${title} Estimate`,
        body: getEmailBody()
      });
    }
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        marginVertical: 20,
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#000000'
      }}
    >
      <View style={styles.logoContainer}>
        <Image
          source={require('../assets/images/fullmer-logo.png')}
          style={styles.logoImage}
        />
      </View>
      <View style={{ alignItems: 'center' }}>
        <Text style={[styles.text, { fontSize: 30, marginBottom: 10 }]}>
          {title}
        </Text>
        <View style={{ marginBottom: 20 }}>
          {resultsArray.map(({ label, value }, index) => (
            <View
              key={index}
              style={{ alignItems: 'center', marginVertical: 5 }}
            >
              <Text
                style={[styles.text, { fontSize: 18, textAlign: 'center' }]}
              >
                {label}: {value}
              </Text>
            </View>
          ))}
        </View>
        <View style={{ alignItems: 'center' }}>
          <Button
            onPress={() => sendToEmail()}
            title="Send as Email"
            style={{ marginBottom: 5 }}
          />
        </View>
      </View>

      <Text style={styles.text}>
        Generated on {date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()}
      </Text>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  logoImage: {
    width: 250,
    height: 80,
    resizeMode: 'contain',
    marginTop: 3,
    marginLeft: -10
  },
  text: {
    color: '#FFFFFF'
  }
});
