import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

export default function ({ title, subTitle }) {
  return (
    <View style={styles.row}>
      <View style={styles.col}>
        <Text style={{ fontWeight: '300', color: '#5D5D5D', fontSize: 13 }}>
          CONSTRUCTION TYPE:
        </Text>
      </View>
      <View style={styles.col}>
        {subTitle && <Text style={styles.subTitle}>{subTitle}</Text>}
        <Text style={styles.title}>{title}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    marginBottom: 10
  },
  col: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 10
  },
  title: {
    fontWeight: '700'
  },
  subTitle: {
    color: 'red',
    textTransform: 'uppercase',
    fontSize: 12
  }
});
