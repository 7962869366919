class ClearHeightCostMultiplier {
  public error;

  public value;

  private baselineClearHeightData;
  private clearHeightCostAdjustmentData;

  constructor(baselineClearHeightData, clearHeightCostAdjustment) {
    this.baselineClearHeightData = baselineClearHeightData;
    this.clearHeightCostAdjustmentData = clearHeightCostAdjustment;
  }

  public inputs = {
    clearHeight: null,
    buildingSf: null
  };

  isValidInput() {
    return (
      this.inputs.buildingSf &&
      this.inputs.buildingSf.isBetween(25001, 1250000) &&
      this.inputs.clearHeight &&
      this.inputs.clearHeight.isBetween(24, 44) &&
      this.clearHeightIsInRange()
    );
  }

  clearHeightIsInRange() {
    if (!this.inputs.buildingSf || !this.inputs.clearHeight) return false;
    if (
      this.inputs.buildingSf.isBetween(900001, 1250000) &&
      this.inputs.clearHeight.isBetween(36, 44)
    ) {
      return true;
    }
    if (
      this.inputs.buildingSf.isBetween(600001, 900000) &&
      this.inputs.clearHeight.isBetween(32, 44)
    ) {
      return true;
    }
    if (
      this.inputs.buildingSf.isBetween(200001, 600000) &&
      this.inputs.clearHeight.isBetween(32, 40)
    ) {
      return true;
    }
    if (
      this.inputs.buildingSf.isBetween(50000, 200000) &&
      this.inputs.clearHeight.isBetween(28, 36)
    ) {
      return true;
    }
    if (
      this.inputs.buildingSf.isBetween(25001, 50000) &&
      this.inputs.clearHeight.isBetween(24, 32)
    ) {
      return true;
    }
    return false;
  }

  get baselineClearHeight(): number {
    for (const value of this.baselineClearHeightData.values) {
      if (this.inputs.buildingSf.isBetween(value.minimum, value.maximum)) {
        return parseFloat(value.output);
      }
    }
    return 0;
  }

  get clearHeightDeviation(): number {
    return this.inputs.clearHeight - this.baselineClearHeight;
  }

  calculateCostAdjustment(): number {
    for (const value of this.clearHeightCostAdjustmentData.values) {
      if (this.clearHeightDeviation === value.minimum) {
        return parseFloat(value.output);
      }
    }
    
    return 0;
  }

  execute() {
    if (!this.isValidInput()) {
      this.error = 'Clear height could not be calculated';
      throw new Error(this.error);
    }
    this.value = this.calculateCostAdjustment();
    return this.value;
  }
}

export default ClearHeightCostMultiplier;
