import React from 'react';
import { StyleSheet, TouchableOpacity, Text } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

export default function Button({
  title,
  onPress,
  style,
  outline,
  icon,
  ...props
}: Props) {
  return (
    <TouchableOpacity
      style={[styles.button, style, outline && styles.buttonOutline]}
      onPress={onPress}
      {...props}
    >
      {icon && (
        <Ionicons
          name={icon}
          style={styles.icon}
          color={outline ? 'red' : '#fff'}
          size={22}
        />
      )}
      <Text style={[styles.text, outline && styles.textOutline]}>{title}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'red',
    paddingVertical: 10,
    paddingHorizontal: 5,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row'
  },
  icon: {
    marginRight: 5
  },
  text: {
    color: '#fff',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: 18,
    fontFamily: 'Roboto_700Bold',
    textTransform: 'uppercase'
  },
  buttonOutline: {
    backgroundColor: '#fff',
    borderColor: 'red',
    borderWidth: 1
  },
  textOutline: {
    color: 'red'
  }
});

type Props = {
  title: string;
  style?: object;
  outline?: boolean;
  icon?: string;
  onPress(): void;
};
