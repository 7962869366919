import React from 'react';
import InputWrapper from './inputs/InputWrapper';
import { ToggleInput } from './inputs';

export default function({ valueType, onToggle, label = null }) {
  return (
    <InputWrapper
      label={label || 'Units'}
      headerRight={
        <ToggleInput valueType={valueType} onToggle={onToggle}></ToggleInput>
      }
    ></InputWrapper>
  );
}
