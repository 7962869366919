import * as WebBrowser from 'expo-web-browser';
import React, { useState, useEffect } from 'react';
import {
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  ScrollView,
  StatusBar
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Button from '../components/Button';

export default function AboutScreen() {
  const [data, setData] = useState({ ops: [] });

  useEffect(() => {
    AsyncStorage.getItem('CONTENT_DATA').then((data) => {
      const json = JSON.parse(data);
      setData(json.about.content_delta);
    });
  }, []);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
      >
        {data.ops.map((op, idx) => {
          op.insert = op.insert.replace(/[\r\n]+/gm, '');
          if (op.attributes && op.attributes.size === 'huge') {
            return (
              <View style={styles.headingWrap} key={idx}>
                <Text style={styles.heading}>{op.insert}</Text>
              </View>
            );
          }

          if (op.attributes && op.attributes.size === 'large') {
            return (
              <Text style={styles.subheading} key={idx}>
                {op.insert}
              </Text>
            );
          }

          return (
            <Text style={styles.text} key={idx}>
              {op.insert}
            </Text>
          );
        })}
        <Button
          title="Visit Website"
          style={styles.button}
          onPress={() =>
            WebBrowser.openBrowserAsync('http://www.fullmerco.com')
          }
        />
      </ScrollView>
    </SafeAreaView>
  );
}

AboutScreen.navigationOptions = {
  headerShown: false
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#000',
    paddingHorizontal: 20,
    flex: 1
  },
  contentContainer: {
    paddingTop: StatusBar.currentHeight
  },
  headingWrap: {
    borderBottomColor: 'red',
    borderBottomWidth: 1,
    marginTop: 8,
    marginBottom: 3
  },
  heading: {
    color: '#fff',
    fontSize: 25
  },
  subheading: {
    color: '#fff',
    marginBottom: 2,
    fontSize: 20,
    marginTop: 3
  },
  text: {
    color: '#fff',
    marginVertical: 3,
    fontSize: 15
  },
  button: {
    marginVertical: 20
  }
});
