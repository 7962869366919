import React, { Component } from 'react';
import {
  NavigationParams,
  NavigationScreenProp,
  NavigationState
} from 'react-navigation';
import { formatCurrency } from '../../helpers';
import SubResultContainer from '../../components/SubResultContainer';
import { PickerInput, NumberInput } from '../../components/inputs';
import SingleCalculatorScreen from './SingleCalculatorScreen';
import MainResult from '../../components/MainResult';
import Result from '../../components/Result';
import OffSiteCalculator, {
  InputValues,
  Utilities,
  StreetWidth
} from '../../calculators/OffSiteImprovement';

class OffSiteScreen extends Component<Props, State> {
  calculator: OffSiteCalculator;

  constructor(props) {
    super(props);
    this.calculator = new OffSiteCalculator();
    this.state = {
      showResults: false,
      showModal: false,
      linealFt: 2000,
      streetWidth: StreetWidth.Full,
      utilities: Utilities.New,
      results: {
        totalProjectCost: 0,
        costPerLinealFt: 0
      }
    };
  }

  calculateResults() {
    const { linealFt, streetWidth, utilities } = this.state;
    try {
      this.calculator
        .setLinealFt(linealFt)
        .setStreetWidth(streetWidth)
        .setUtilties(utilities);
      const results = this.calculator.calculate();
      this.setState({ results: { ...results }, showResults: true });
    } catch (e) {
      console.log(e);
      this.setState({ showModal: true, showResults: false });
    }
  }

  getInputsArray() {
    return [
      {
        label: 'Lineal Ft',
        value: Number(this.state.linealFt).toLocaleString()
      },
      {
        label: 'Street Width',
        value: this.state.streetWidth
      },
      {
        label: 'Utilites',
        value:
          this.state.streetWidth === StreetWidth.Half
            ? this.state.utilities
            : 'N/A'
      }
    ];
  }

  getResultsArray() {
    return [
      {
        label: 'Total Project Cost',
        value: formatCurrency(this.state.results.totalProjectCost)
      },
      {
        label: 'Cost per SqFt',
        value: formatCurrency(this.state.results.costPerLinealFt)
      }
    ];
  }

  render() {
    const { linealFt, streetWidth, utilities, showModal, showResults } =
      this.state;
    return (
      <SingleCalculatorScreen
        title="Off-Site Improvements"
        subTitle="Inland Empire"
        navigation={this.props.navigation}
        inputsArray={this.getInputsArray()}
        resultsArray={this.getResultsArray()}
        showModal={showModal}
        showResults={showResults}
        onModalClosed={() => this.setState({ showModal: false })}
        onCalculate={() => this.calculateResults()}
        results={
          this.state.showResults && (
            <>
              <MainResult
                value={this.state.results.totalProjectCost}
                description="Total Project Cost"
              />
              <SubResultContainer>
                <Result
                  value={`${this.state.results.costPerLinealFt}`}
                  description="Cost per sqft"
                  currency
                />
              </SubResultContainer>
            </>
          )
        }
      >
        <NumberInput
          label="Lineal Ft"
          value={linealFt.toString()}
          onChange={(val) => this.setState({ linealFt: parseInt(val) })}
          maxLength={7}
        />

        <PickerInput
          label="Street Width"
          options={Object.entries(StreetWidth).map((option) => ({
            label: option[1],
            value: option[0]
          }))}
          value={streetWidth}
          onValueChange={(val) => this.setState({ streetWidth: val })}
        />
        {streetWidth === StreetWidth.Half && (
          <PickerInput
            label="Utilites"
            options={Object.entries(Utilities).map((option) => ({
              label: option[1],
              value: option[0]
            }))}
            value={utilities}
            onValueChange={(val) => this.setState({ utilities: val })}
          />
        )}
      </SingleCalculatorScreen>
    );
  }
}

export default OffSiteScreen;

type BaseState = {
  showResults: boolean;
  showModal: boolean;
  results: {
    totalProjectCost: number;
    costPerLinealFt: number;
  };
};

type State = InputValues & BaseState;

type Props = {
  navigation: NavigationScreenProp<NavigationState, NavigationParams>;
};
