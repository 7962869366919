class Duration {
  public value;
  public error;
  public inputs = {
    buildingSf: null
  };

  private durationData;

  constructor(durationData) {
    this.durationData = durationData;
  }

  isValidInput() {
    return !!this.inputs.buildingSf;
  }

  setBuildingSf(value) {
    this.inputs.buildingSf = value;
  }

  execute() {
    if (!this.isValidInput()) {
      this.error = 'Duration could not be calculated, invalid inputs.';
      throw new Error(this.error);
    }
    for (const value of this.durationData.values) {
      if (this.inputs.buildingSf.isBetween(value.minimum, value.maximum)) {
        this.value = parseFloat(value.output);
        return this.value;
      }
    }
  }
}

export default Duration;
