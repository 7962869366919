import React, { ReactElement } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Currency from '../components/Currency';
import { text } from '../assets/styles';

export default function Result({ value, description, currency }: Props) {
  return (
    <View style={styles.subResult}>
      {currency ? (
        <Currency
          style={[styles.subResultText, text.sansSerif]}
          value={Number(value)}
          prefix="$"
        ></Currency>
      ) : (
        <Text style={[styles.subResultText, text.sansSerif]}>{value}</Text>
      )}
      <Text style={styles.subResultDescription}>{description}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  subResult: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 10,
    marginVertical: 10
  },
  subResultText: {
    fontSize: 25,
    textAlign: 'center'
  },
  subResultDescription: {
    textTransform: 'uppercase',
    fontSize: 10,
    textAlign: 'center'
  }
});

type Props = {
  value: number | string | ReactElement;
  description: string;
  currency?: boolean;
};
