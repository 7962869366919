import React from 'react';
import { StyleSheet, View } from 'react-native';
import InputWrapper from './InputWrapper';

export default function PickerInput({
  options,
  label,
  onValueChange,
  ...props
}: Props) {
  return (
    <InputWrapper label={label}>
      <View style={{ marginBottom: 10 }}></View>
      <select onChange={event => onValueChange(event.target.value)}>
        {options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.label}
          </option>
        ))}
      </select>
      <View style={{ marginBottom: 10 }}></View>
    </InputWrapper>
  );
}

type Props = {
  label: string;
  options: [{ label: string; value: string }];
  onValueChange(value: string);
};
