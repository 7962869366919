import React from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import Button from './Button'

function Separator() {
    return (
        <View style={styles.separator}>
            <Text style={styles.separatorText}>OR</Text>
        </View>
    );
}

export default function Intro(props) {
    const { onPressStartCalculating, onPressLearnMore } = props;
    return (
        <View style={styles.container}>
            <View style={styles.content}>
                <Text style={styles.welcomeText}>WELCOME</Text>
                <Text style={styles.headingText}>
                    MEET <Text style={styles.headingTextRed}>FULLMER</Text>
                </Text>
                <Text style={styles.introText}>
                    Fullmer has been building relationships in the construction industry since 1946. Throughout the years the company has found success through those relationships.
                </Text>
                <Button title="Start Calculating" onPress={() => onPressStartCalculating()} />
                <Separator />
                <Button 
                    outline 
                    title="Learn More" 
                    style={styles.buttonOutline} 
                    onPress={() => onPressLearnMore()}
                />
            </View>
            <Image 
                source={require('../assets/images/BuildingOverlay.png')}
                resizeMode='contain'
                style={styles.bgImage} />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        overflow: "hidden",
        justifyContent: 'space-between'
    },
    content: {
        alignItems: "center",
        padding: 10,
        paddingTop: 30,
    },
    welcomeText: {
        fontSize: 13,
        opacity: 0.5,
        marginBottom: 10
    },
    headingText: {
        fontSize: 22,
        marginBottom: 10
    },
    headingTextRed: {
        color: 'red'
    },
    introText: {
        fontSize: 16,
        opacity: 0.7,
        textAlign: "center",
        marginBottom: 30
    },
    separator: {
        marginVertical: 20,
        alignSelf:'stretch',
        width: '100%',
        borderBottomColor: '#718096',
        borderBottomWidth: StyleSheet.hairlineWidth,
        alignItems: 'center',
        justifyContent: 'center'
    },
    separatorText: {
        position: 'absolute',
        backgroundColor: '#fff',
        paddingLeft: 10,
        paddingRight: 10,
        color: '#718096'
    },
    button: {
        flex: 1
    }
})