import React, { useState, useEffect } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  ScrollView,
  StatusBar
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function LegalDisclaimerScreen() {
  const [data, setData] = useState({ ops: [] });

  useEffect(() => {
    AsyncStorage.getItem('CONTENT_DATA').then((data) => {
      const json = JSON.parse(data);
      setData(json.legal.content_delta);
    });
  }, []);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
      >
        <View style={styles.headingWrap}>
          <Text style={styles.heading}>Legal Disclosure</Text>
        </View>
        {data.ops.map((op, idx) => {
          return (
            <View style={{ marginBottom: 5 }} key={idx}>
              <Text style={{ color: '#FFFFFF' }}>{op.insert}</Text>
            </View>
          );
        })}
      </ScrollView>
    </SafeAreaView>
  );
}

LegalDisclaimerScreen.navigationOptions = {
  title: 'Disclosure'
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#000',
    paddingHorizontal: 20,
    flex: 1
  },
  contentContainer: {
    paddingTop: StatusBar.currentHeight
  },
  headingWrap: {
    borderBottomColor: 'red',
    borderBottomWidth: 1,
    marginTop: 8,
    marginBottom: 10
  },
  heading: {
    color: '#fff',
    fontSize: 25
  },
  subheading: {
    color: '#fff',
    marginBottom: 2,
    fontSize: 20,
    marginTop: 3
  },
  text: {
    color: '#FFFFFF',
    fontSize: 15
  },
  button: {
    marginVertical: 20
  }
});
