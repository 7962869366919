import React, { useState, CSSProperties } from 'react';
import { Text, View, TouchableHighlight, StyleProp } from 'react-native';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
const formatNumber = (value: number): string =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export default function Currency({
  value,
  truncateMillions = true,
  style,
  prefix = ''
}: Props) {
  const [showFullValue, setShowFullValue] = useState(false);

  let isMillion = false;
  let _value = Number(value);

  if (truncateMillions && !showFullValue) {
    if (value > 1000000) {
      isMillion = true;
      // https://stackoverflow.com/a/29494612/2611917
      _value = Math.round((_value / 1000000) * 1e2) / 1e2;
    }
  }

  let stringVal = prefix + formatNumber(_value);

  if (!showFullValue && truncateMillions && isMillion) {
    stringVal += 'M';
  }

  return (
    <TouchableHighlight onPress={() => setShowFullValue(!showFullValue)}>
      <View>
        {showFullValue ? (
          <Text style={style}>{stringVal}</Text>
        ) : (
          <Text style={style}>{stringVal}</Text>
        )}
      </View>
    </TouchableHighlight>
  );
}

interface Props {
  value: number;
  truncateMillions?: boolean;
  style?: object;
  prefix?: string;
}
