import React from 'react';
import * as ReactSvg from 'react-native-svg';

const { Svg, Path, Circle } = ReactSvg;

export default function AboutIcon({ focused }) {
  return (
    <Svg viewBox="0 0 30 30" width="25" height="25">
      <Path
        fill="#333"
        d="M15 0a15 15 0 1015 15A15.017 15.017 0 0015 0zm0 28.154A13.154 13.154 0 1128.154 15 13.169 13.169 0 0115 28.154z"
      />
      <Circle
        fill={focused ? 'red' : '#333'}
        cx="1.544"
        cy="1.544"
        r="1.544"
        transform="translate(13.695 7.475)"
      />
      <Path
        fill={focused ? 'red' : '#333'}
        d="M14.832 13.081a.923.923 0 00-.923.923v7.846a.923.923 0 001.846 0v-7.846a.923.923 0 00-.923-.923z"
      />
    </Svg>
  );
}
