import AppLoading from 'expo-app-loading';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import React, { useState } from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Ionicons } from '@expo/vector-icons';
import { API_URL_BASE, API_TOKEN } from './config';
import { Roboto_400Regular, Roboto_700Bold } from '@expo-google-fonts/roboto';
import Alert from './components/Alert';

declare global {
  interface Number {
    isBetween: (min: number, max: number) => boolean;
  }
}

Number.prototype.isBetween = function (min, max) {
  return this.valueOf() >= min && this.valueOf() <= max;
};

import AppNavigator from './navigation/AppNavigator';

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return (
      <AppLoading
        startAsync={loadResourcesAsync}
        onError={handleLoadingError}
        onFinish={() => handleFinishLoading(setLoadingComplete)}
      />
    );
  } else {
    return (
      <View style={styles.container}>
        {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
        <AppNavigator theme="dark" />
      </View>
    );
  }
}

async function loadResourcesAsync() {
  await Promise.all([
    Asset.loadAsync([]),
    Font.loadAsync({
      ...Ionicons.font,
      Roboto_400Regular,
      Roboto_700Bold
    }),
    loadCalculatorData(),
    loadContentData()
  ]);
}

async function displayDataFetchAlert(error) {
  const hasExistingData = !!(await AsyncStorage.getItem('CALC_DATA'));
  const action = hasExistingData ? 'updating' : 'downloading';
  const message = hasExistingData
    ? 'Your data may not be up to date.'
    : 'Check your network connection and try restarting the app.';
  Alert.alert(`Error ${action} data`, message);
}

function loadCalculatorData() {
  return fetch(`${API_URL_BASE}/calculators?api_token=${API_TOKEN}`)
    .then((response) => response.json())
    .then((json) => {
      AsyncStorage.setItem('CALC_DATA', JSON.stringify(json.data));
    });
}

function loadContentData() {
  return fetch(`${API_URL_BASE}/content?api_token=${API_TOKEN}`)
    .then((response) => response.json())
    .then((json) => {
      AsyncStorage.setItem('CONTENT_DATA', JSON.stringify(json.data));
    });
}

function handleLoadingError(error) {
  displayDataFetchAlert(error);
}

function handleFinishLoading(setLoadingComplete) {
  setLoadingComplete(true);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  }
});
