import React from 'react';
import {
  SafeAreaView,
  StyleSheet,
  View,
  Dimensions,
  Image,
  Text,
  StatusBar,
  TouchableOpacity
} from 'react-native';
import { withNavigation } from 'react-navigation';

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');
const overlapOffset = 80;

function Layout({ children, navigation, showLogo = false }) {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <StatusBar barStyle="light-content" backgroundColor="#000" />
      <View style={styles.background}></View>
      <View style={styles.container}>
        <View style={styles.logoContainer}>
          {showLogo && (
            <Image
              source={require('../assets/images/fullmer-logo.png')}
              style={styles.logoImage}
            />
          )}
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.content}>{children}</View>
          <TouchableOpacity
            onPress={() => navigation.navigate('LegalDisclaimer')}
          >
            <Text
              style={{
                fontSize: 15,
                marginVertical: 7,
                textDecorationLine: 'underline'
              }}
            >
              Legal Disclaimer
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
}

export default withNavigation(Layout);

const styles = StyleSheet.create({
  background: {
    position: 'absolute',
    backgroundColor: '#000',
    minHeight: SCREEN_HEIGHT / 3,
    width: '100%',
    top: 0
  },
  container: {
    paddingTop: 10,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: overlapOffset
  },
  logoImage: {
    width: 250,
    height: 80,
    resizeMode: 'contain',
    marginTop: 3,
    marginLeft: -10
  },
  contentContainer: {
    backgroundColor: '#fff',
    flex: 1,
    alignItems: 'center'
  },
  content: {
    width: SCREEN_WIDTH * 0.9,
    backgroundColor: '#fff',
    flex: 1,
    borderRadius: 5,
    marginTop: -overlapOffset,
    elevation: 3,
    shadowOffset: { width: 4, height: 4 },
    shadowColor: 'grey',
    shadowOpacity: 0.5,
    shadowRadius: 10,
    marginBottom: 5,
    borderWidth: 1,
    borderColor: '#ddd'
  },
  spacer: {
    height: 300
  }
});
